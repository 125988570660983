import dayjs from "dayjs";
import {
  convertDurationToHumanReadable,
  convertTimeToHours,
  getHumanizedDifference,
  getReadableDateRange,
  minutesToLongTime,
  parseAndFormatProvidedTime,
} from "./dateTime";

export const groupNotificationsData = (data) => {
  return Object.values(
    data.reduce((result, obj) => {
      const key = obj.messageTypeGroupId;
      if (!result[key]) {
        result[key] = {
          id: obj.messageTypeGroupId,
          title: obj.messageTypeGroupTitle,
          name: obj.messageTypeGroupName,
          notificationsType: [],
        };
      }
      result[key].notificationsType.push({
        id: obj.id,
        name: obj.name,
        title: obj.title,
      });
      return result;
    }, {}),
  );
};

export const convertNumberToString = (number) => {
  return number.toString();
};

export const convertStringToNumber = (value) => {
  return parseInt(value);
};

export const extractAndParseValue = (
  obj,
  key,
  defaultValue,
  parseFunction = (value) => value,
) => {
  if (!obj) {
    return defaultValue;
  }

  const keys = key.split(".");
  let value = obj;

  for (const nestedKey of keys) {
    if (!Object.prototype.hasOwnProperty.call(value, nestedKey)) {
      value = undefined;
      break;
    }
    value = value[nestedKey];
  }

  if (!value && value !== 0 && value !== false) {
    return defaultValue;
  }
  return parseFunction(value);
};

export const parseWorkingCalendarTimes = (workingWeekCalendar) => {
  const groupedByWorkingTime = workingWeekCalendar.reduce(
    (acc, { dayOfWeek, workingTime }) => {
      const [startTime, endTime] = workingTime
        .split("T")
        .map((time) => time.slice(0, 5));

      if (!acc[workingTime]) {
        acc[workingTime] = {
          daysOfWeek: [dayOfWeek.toString()],
          startTime,
          endTime,
        };
      } else {
        let existingDays = [...acc[workingTime].daysOfWeek];
        existingDays.push(dayOfWeek.toString());
        existingDays = existingDays.sort((a, b) => {
          if (a === "0") {
            return 1;
          } else if (b === "0") {
            return -1;
          } else {
            return a - b;
          }
        });
        acc[workingTime].daysOfWeek = existingDays;
      }

      return acc;
    },
    {},
  );

  return Object.values(groupedByWorkingTime);
};

export const parseWorkingTimeSlices = (calendarDays) => {
  const groupedByWorkingTime = calendarDays.reduce(
    (acc, { dayOfWeek, workingTime }) => {
      const slices = workingTime.split(",").map((stringifiedSlice) => {
        const [start, end] = stringifiedSlice.split("T").slice(0, 5);
        return {
          start,
          end,
        };
      });

      if (!acc[workingTime]) {
        acc[workingTime] = {
          daysOfWeek: [dayOfWeek.toString()],
          workingTimes: slices,
        };
      } else {
        let existingDays = [...acc[workingTime].daysOfWeek];
        existingDays.push(dayOfWeek.toString());
        existingDays = existingDays.sort((a, b) => {
          if (a === "0") {
            return 1;
          } else if (b === "0") {
            return -1;
          } else {
            return a - b;
          }
        });
        acc[workingTime].daysOfWeek = existingDays;
      }

      return acc;
    },
    {},
  );

  return Object.values(groupedByWorkingTime);
};

const ungroupeWorkingDaysData = (currentData, updatedData) => {
  const workingDaysUngrouped = [];
  for (const groupedWeedDays of updatedData) {
    const ungroupedDays = groupedWeedDays.daysOfWeek.map((day) => {
      const numberedDay = convertStringToNumber(day);
      const weekdayObj = {
        dayOfWeek: numberedDay,
        workingTime: [groupedWeedDays.startTime, groupedWeedDays.endTime].join(
          "T",
        ),
        id: currentData.find(({ dayOfWeek }) => dayOfWeek === numberedDay)?.id,
      };
      if (!weekdayObj.id) {
        delete weekdayObj.id;
      }
      return weekdayObj;
    });
    workingDaysUngrouped.push(...ungroupedDays);
  }
  return workingDaysUngrouped;
};

const prepareWorkingCalendarData = (currentData, updatedData) => {
  return {
    id: extractAndParseValue(currentData, "id", undefined),
    calendarExceptions: extractAndParseValue(
      currentData,
      "calendarExceptions",
      [],
    ),
    calendarWeekDays: ungroupeWorkingDaysData(
      extractAndParseValue(currentData, "calendarWeekDays", []),
      updatedData,
    ),
  };
};

const ungroupeMeetingBlocksDaysData = (currentData, updatedData) => {
  const meetingBlocksUnGrouped = [];
  for (const groupedWeedDays of updatedData) {
    const ungroupedDays = groupedWeedDays.daysOfWeek.map((day) => {
      const numberedDay = convertStringToNumber(day);
      const weekdayObj = {
        dayOfWeek: numberedDay,
        workingTime: groupedWeedDays.workingTimes
          .map(({ start, end }) => `${start}T${end}`)
          .join(","),
        id: currentData.find(({ dayOfWeek }) => dayOfWeek === numberedDay)?.id,
      };
      if (!weekdayObj.id) {
        delete weekdayObj.id;
      }
      return weekdayObj;
    });
    meetingBlocksUnGrouped.push(...ungroupedDays);
  }
  return meetingBlocksUnGrouped;
};

const prepareMeetingSlicesPreferences = (currentData, updatedData) => {
  return {
    id: extractAndParseValue(currentData, "id", undefined),
    calendarExceptions: extractAndParseValue(
      currentData,
      "calendarExceptions",
      [],
    ),
    calendarWeekDays: ungroupeMeetingBlocksDaysData(
      extractAndParseValue(currentData, "calendarWeekDays", []),
      updatedData,
    ),
  };
};

const parseMeetingPlatformTitle = (platformTItle) => {
  return platformTItle === "None" ? "Offline" : platformTItle;
};

const getAllParticipantsInEvent = (invitees, host, userToExcludeId) => {
  let participants = [...invitees];
  participants.push(host);
  // const allParticipants = participants;

  if (userToExcludeId) {
    participants = participants.filter((item) => item.id !== userToExcludeId);
  }

  // let overflowUsers = 0;
  // if (participants.length > 4) {
  //   overflowUsers = participants.length - 3;
  //   participants = participants.slice(0, 3);
  // }
  return {
    participants,
    // allParticipants: allParticipants.length,
    // remainingCounter: overflowUsers,
  };
};

export const queryfyUrl = (url, queryParams) => {
  let newUrl = url;
  const keys = Object.keys(queryParams);

  if (keys.length > 0) {
    const queryString = keys
      .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join("&");

    newUrl += newUrl.includes("?") ? `&${queryString}` : `?${queryString}`;
  }

  return newUrl;
};

export const parseProfileFormsDataForApi = (
  currentData,
  profileValues,
  notificationsValues,
) => {
  const currentProfileData = {
    userId: extractAndParseValue(currentData, "userId", ""),
    emailNotificationsEnabled: extractAndParseValue(
      currentData,
      "emailNotificationsEnabled",
      true,
    ),
    firstName: extractAndParseValue(profileValues, "userFirstName", ""),
    lastName: extractAndParseValue(profileValues, "userLastName", ""),
    userName: extractAndParseValue(profileValues, "userName", ""),
    phoneNumber: extractAndParseValue(profileValues, "phoneNumber", ""),
    timeZone: extractAndParseValue(profileValues, "timeZone", ""),
    enabledMessageTypeIdsForNotifications: extractAndParseValue(
      notificationsValues,
      "enabledMessageTypeIdsForNotifications",
      null,
    ),
  };

  return currentProfileData;
};

export const parseMeetingPreferencesDataForApi = (
  currentPreferences,
  updatedPrencesValues,
  userData,
) => {
  const preferences = {
    id: extractAndParseValue(currentPreferences, "id", undefined),
    createdBy: extractAndParseValue(
      currentPreferences,
      "createdBy",
      userData?.userId,
    ),
    lastModifiedBy: extractAndParseValue(
      currentPreferences,
      "lastModifiedBy",
      userData?.userId,
    ),
    personID: extractAndParseValue(currentPreferences, "personID", undefined),
    timeZone: extractAndParseValue(
      currentPreferences,
      "timeZone",
      userData?.timeZone,
    ),
    overTimeFlexibilityBeforeStart: extractAndParseValue(
      updatedPrencesValues,
      "overTimeFlexibilityBeforeStart",
      0,
      convertStringToNumber,
    ),
    overTimeFlexibilityAfterEnd: extractAndParseValue(
      updatedPrencesValues,
      "overTimeFlexibilityAfterEnd",
      0,
      convertStringToNumber,
    ),
    meetingBlockPreference: extractAndParseValue(
      updatedPrencesValues,
      "meetingBlockPreference",
      "",
    ),
    requiredBreakIfMaxReached: extractAndParseValue(
      updatedPrencesValues,
      "requiredBreakIfMaxReached",
      "",
    ),
    workingCalendar: prepareWorkingCalendarData(
      currentPreferences?.workingCalendar || {},
      updatedPrencesValues.calendarWeekDays,
    ),
    blockedMeetingsCalendar: prepareMeetingSlicesPreferences(
      currentPreferences?.blockedMeetingsCalendar || {},
      updatedPrencesValues.blockedMeetingWindows,
    ),
    preferenceForMeetingPeriodsCalendar: prepareMeetingSlicesPreferences(
      currentPreferences?.preferenceForMeetingPeriodsCalendar || {},
      updatedPrencesValues.preferentialMeetingWindows,
    ),
  };
  return preferences;
};
function assignPersonIDsToArray(valuesArray) {
  return valuesArray.map((value) => ({
    personID: value,
  }));
}

export const parseCreateMeetingRequestDataForApi = (
  formValues,
  currentUser,
) => {

  let startDate = new Date(formValues.dateTimesToBeHeldStart);
  let endDate = new Date(formValues.dateTimesToBeHeldEnd);

  startDate.setHours(0, 0, 0, 0);
  let localStartDate = new Date(
    startDate.getTime() - startDate.getTimezoneOffset() * 60000
  ).toISOString();

  endDate.setHours(23, 59, 59, 999);
  let localEndDate = new Date(
    endDate.getTime() - endDate.getTimezoneOffset() * 60000
  ).toISOString();

  return {
    createdBy: extractAndParseValue(currentUser, "userId", ""),
    lastModifiedBy: extractAndParseValue(currentUser, "userId", ""),
    hostPersonID: extractAndParseValue(currentUser, "userId", ""),
    topic: extractAndParseValue(formValues, "topic", ""),
    agenda: extractAndParseValue(formValues, "agenda", ""),
    id: formValues?.id,
    platformId: extractAndParseValue(formValues, "platformId", 0),
    dateTimesToBeHeldStart: localStartDate,
    dateTimesToBeHeldEnd: localEndDate,
    invitees: extractAndParseValue(
      formValues,
      "invitees",
      [],
      assignPersonIDsToArray,
    ),
    externalInvitees: extractAndParseValue(formValues, "externalInvitees", []),
    minLength: extractAndParseValue(
      formValues,
      "minLength",
      "00:00:00",
      minutesToLongTime,
    ),
    internalInvitees: extractAndParseValue(
      formValues,
      "invitees",
      [],
      assignPersonIDsToArray,
    ),
    nonAdjustable: extractAndParseValue(formValues, "nonAdjustable", false),
  };
};

export const groupeScheduledEventsData = (existingEvents, result) => {
  const updatedObject = { ...existingEvents };

  for (const scheduledEventItem of result) {
    parseEventItem(updatedObject, scheduledEventItem);
  }
  return updatedObject;
};

const parseEventItem = (meetingsObject, eventItemData, eventCount = 1) => {
  // Determine if the event is multi-day or all-day
  const isMultiDayOrAllDay = dayjs(eventItemData.end).diff(dayjs(eventItemData.start), 'day') >= 1;

  // Extract and convert start and end times to appropriate timezone
  const startDateTime = !isMultiDayOrAllDay ? dayjs(eventItemData.start) : dayjs.utc(eventItemData.start);
  const endDateTime = !isMultiDayOrAllDay ? dayjs(eventItemData.end) : dayjs.utc(eventItemData.end);

  // Format the start date consistently
  const startDate = startDateTime.format('YYYY-MM-DD');

  if (!meetingsObject[startDate]) {
    meetingsObject[startDate] = [];
  }
  const existingMeetingsInDay = meetingsObject[startDate];

  // Meeting was added already before - skip the parsing
  const meetingExists = existingMeetingsInDay.some(
    (item) =>
      item.entityID === eventItemData.entityID ||
      (item.privateMeeting_EventId &&
        item.privateMeeting_EventId === eventItemData.privateMeeting_EventId)
  );
  if (meetingExists) return;

  let adjustedEndDateTime = endDateTime;

  // Check if the endDateTime goes into the next day
  if (endDateTime.diff(startDateTime, 'd') > 0) {
    adjustedEndDateTime = startDateTime.endOf('d');
    parseEventItem(
      meetingsObject,
      {
        ...eventItemData,
        start: startDateTime.add(1, 'd').startOf('d').format(),
        end: endDateTime.format(),
      },
      eventCount + 1
    );
  }

  const startTime = startDateTime.format('HH:mm');
  const endTime = adjustedEndDateTime.format('HH:mm');

  const eventDuration = parseFloat(
    adjustedEndDateTime.diff(startDateTime, 'minutes') / 60
  )
    .toFixed(2)
    .replace('.00', '');

  const eventDurationDisplay = `${startDateTime.format(
    'HH:mm'
  )} - ${adjustedEndDateTime.format('HH:mm')}`;

  const { participants, remainingCounter, allParticipants } =
    getAllParticipantsInEvent(eventItemData.invitees, {
      id: eventItemData.hostId,
      firstName: eventItemData.hostFirstName,
      lastName: eventItemData.hostLastName,
    });

  const updatedEventItem = {
    ...eventItemData,
    startTime: startTime,
    endTime: endTime,
    eventDuration,
    eventDurationDisplay,
    isProposal: extractAndParseValue(eventItemData, 'isProposal', false),
    nonAdjustable: extractAndParseValue(eventItemData, 'nonAdjustable', true),
    meetingPlatform: extractAndParseValue(
      eventItemData,
      'platformTitle',
      '',
      parseMeetingPlatformTitle
    ),
    participants,
    remainingCounter,
    allParticipants,
    isExternalMeeting: extractAndParseValue(
      eventItemData,
      'privateMeeting_EventId',
      false,
      (value) => !!value
    ),
    multidaysEventKey: `${eventItemData.entityID}_${eventCount}`,
  };

  meetingsObject[startDate] = [...meetingsObject[startDate], updatedEventItem];
  return;
};


const sliceOutText = (text) => {
  return text.slice(0, 200);
};

const isAgendaTextOverflows = (agendaText) => {
  return agendaText.length > 200;
};

export const parseMeetingDetailData = (data, eventId) => {
  const { participants } = getAllParticipantsInEvent(
    data?.invitees || data.externalInvitees.concat(data.internalInvitees) || [],
    {
      id: data.hostPersonID,
      firstName: data.hostPersonFirstName,
      lastName: data.hostPersonLastName,
    },
  );
  return {
    id: extractAndParseValue(data, "id", eventId),
    topic: extractAndParseValue(data, "topic", ""),
    agenda: extractAndParseValue(data, "agenda", "", sliceOutText),
    agendaFull: extractAndParseValue(data, "agenda", ""),
    canExapandAgendaText: extractAndParseValue(
      data,
      "agenda",
      false,
      isAgendaTextOverflows,
    ),
    meetingWindow: getReadableDateRange(data.start, data.end),
    scheduledDateTime: extractAndParseValue(
      data,
      "start",
      "",
      parseAndFormatProvidedTime("DD MMM YYYY HH:mm"),
    ),
    meetingDuration: getHumanizedDifference(data.start, data.end),
    hostPersonID: extractAndParseValue(data, "hostPersonID", null),
    host: `${data.hostPersonFirstName} ${data.hostPersonLastName}`,
    lastModifiedOn: extractAndParseValue(
      data,
      "lastModificationTime",
      "",
      parseAndFormatProvidedTime("DD MMM YYYY"),
    ),
    meetingPlatform: extractAndParseValue(
      data,
      "platformTitle",
      "",
      parseMeetingPlatformTitle,
    ),
    platformId: extractAndParseValue(data, "platformId", null),
    proposalID: extractAndParseValue(data, "proposalID", null),
    nonAdjustable: extractAndParseValue(data, "nonAdjustable", false),
    participants,
    mepaId: data?.mepaId,
    fixedProposalId: data?.fixedProposalId,
    shouldBeManuallyFixedBefore: data?.shouldBeManuallyFixedBefore,
    fixTime: data?.fixTime,
  };
};

export const parseMeetingProposalData = (data, proposalID) => {
  const externalInvitees = Array.isArray(data.externalInvitees)
    ? data.externalInvitees
    : [];
  const internalInvitees = Array.isArray(data.internalInvitees)
    ? data.internalInvitees
    : [];
  const { participants } = getAllParticipantsInEvent(
    data?.invitees || internalInvitees.concat(externalInvitees) || [],
    {
      id: data.hostPersonID,
      firstName: data.hostPersonFirstName,
      lastName: data.hostPersonLastName,
    },
  );
  return {
    id: extractAndParseValue(data, "id", proposalID),
    topic: extractAndParseValue(data, "topic", ""),
    agenda: extractAndParseValue(data, "agenda", "", sliceOutText),
    agendaFull: extractAndParseValue(data, "agenda", ""),
    canExapandAgendaText: extractAndParseValue(
      data,
      "agenda",
      false,
      isAgendaTextOverflows,
    ),
    meetingWindow: getReadableDateRange(
      data.dateTimesToBeHeldStart,
      data.dateTimesToBeHeldEnd,
    ),
    scheduledDateTime: extractAndParseValue(
      data,
      "start",
      "",
      parseAndFormatProvidedTime("DD MMM YYYY HH:mm"),
    ),
    meetingDuration: extractAndParseValue(
      data,
      "minLength",
      "",
      convertDurationToHumanReadable,
    ),
    hostPersonID: extractAndParseValue(data, "hostPersonID", null),
    host: `${data.hostPersonFirstName} ${data.hostPersonLastName}`,
    lastModifiedOn: extractAndParseValue(
      data,
      "lastModificationTime",
      "",
      parseAndFormatProvidedTime("DD MMM YYYY"),
    ),
    meetingPlatform: extractAndParseValue(
      data,
      "platformTitle",
      "",
      parseMeetingPlatformTitle,
    ),
    platformId: extractAndParseValue(data, "platformId", null),
    proposalID: extractAndParseValue(data, "proposalID", proposalID),
    nonAdjustable: extractAndParseValue(data, "nonAdjustable", false),
    participants,
    mepaId: data?.mepaId,
    fixedProposalId: data?.fixedProposalId,
    shouldBeManuallyFixedBefore: data?.shouldBeManuallyFixedBefore,
    fixTime: data?.fixTime,
  };
};

export const parseMeetingHistorySessions = (historySessionsData) => {
  const schedulingSessions = [];
  let sessionIndex = 1;
  const slicedSessions = historySessionsData.slice(0, 4).reverse();
  for (let index = 0; index < slicedSessions.length; index++) {
    const item = slicedSessions[index];
    const sessionItem = {
      schedulingSessionId: extractAndParseValue(
        item,
        "schedulingSessionId",
        "",
      ),
      schedulingStartTime: extractAndParseValue(
        item,
        "schedulingStartTime",
        "",
        parseAndFormatProvidedTime("DD MMM YYYY HH:mm"),
      ),
      exceutionDetails: extractAndParseValue(item, "exceutionDetails", ""),
      status: extractAndParseValue(item, "status", 0),
      isLastItem: slicedSessions.length === index + 1,
    };
    if (!sessionItem.exceutionDetails) {
      sessionItem.exceutionDetails = `Scheduling Session ${sessionIndex}`;
      sessionIndex += 1;
    }
    schedulingSessions.push(sessionItem);
  }

  return schedulingSessions;
};

const getMeetingSchedule = (meetingDate) => {
  const currentDate = new Date();
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1); // Get tomorrow's date

  // Check if meeting is tomorrow
  if (meetingDate.toDateString() === tomorrowDate.toDateString()) {
    return `Tomorrow at ${meetingDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    })}`;
  }

  // Check if meeting is today
  if (meetingDate.toDateString() === currentDate.toDateString()) {
    return `Today at ${meetingDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    })}`;
  }

  // Check if meeting was yesterday
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(currentDate.getDate() - 1); // Get yesterday's date
  if (meetingDate.toDateString() === yesterdayDate.toDateString()) {
    return `Yesterday at ${meetingDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    })}`;
  }

  // For all other cases
  return `${meetingDate.toLocaleDateString(
    "en-US",
  )} at ${meetingDate.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  })}`;
};

// Example usage:
const meetingDate = new Date("2024-04-15T20:30:00"); // Change this to the meeting date
console.log(getMeetingSchedule(meetingDate));

export const parseMyInvitationsData = (result, currentUserId) => {
  const parsedResult = [];
  for (const invitationRequest of result) {
    const { participants, remainingCounter } = getAllParticipantsInEvent(
      invitationRequest.invitees || [],
      {
        id: invitationRequest.hostPersonID,
        firstName: invitationRequest.hostFirstName,
        lastName: invitationRequest.hostLastName,
      },
      currentUserId,
    );
    const updatedInvitationItem = {
      isHost: currentUserId === invitationRequest.hostPersonID,
      meetingProposalID: extractAndParseValue(
        invitationRequest,
        "meetingProposalID",
        "",
      ),
      mepaId: invitationRequest?.mepaid,
      host: extractAndParseValue(invitationRequest, "hostTitle", ""),
      hostPersonID: extractAndParseValue(invitationRequest, "hostPersonID", ""),
      topic: extractAndParseValue(invitationRequest, "proposalTopic", ""),
      agenda: extractAndParseValue(
        invitationRequest,
        "agenda",
        "",
        sliceOutText,
      ),
      agendaFull: extractAndParseValue(invitationRequest, "agenda", ""),
      canExapandAgendaText: extractAndParseValue(
        invitationRequest,
        "agenda",
        false,
        isAgendaTextOverflows,
      ),
      meetingWindow: getReadableDateRange(
        invitationRequest.dateTimesToBeHeldStart,
        invitationRequest.dateTimesToBeHeldEnd,
      ),
      start: extractAndParseValue(
        invitationRequest,
        "dateTimesToBeHeldStart",
        "",
      ),
      end: extractAndParseValue(invitationRequest, "dateTimesToBeHeldEnd", ""),
      eventDuration: extractAndParseValue(
        invitationRequest,
        "proposalMinLength",
        "",
        convertTimeToHours,
      ),
      meetingDuration: extractAndParseValue(
        invitationRequest,
        "proposalMinLength",
        "",
        convertDurationToHumanReadable,
      ),
      response: extractAndParseValue(invitationRequest, "response", null),
      nonAdjustable: extractAndParseValue(
        invitationRequest,
        "nonAdjustable",
        true,
      ),
      platformId: extractAndParseValue(invitationRequest, "platformId", ""),
      platformTitle: extractAndParseValue(
        invitationRequest,
        "platformTitle",
        "",
        parseMeetingPlatformTitle,
      ),
      invitees: invitationRequest?.externalInvitees.concat(
        invitationRequest?.internalInvitees,
      ),
      participants,
      remainingCounter,
      lastScheduleTime: invitationRequest?.lastScheduleTime,
      proposalDateTimesToBeHeldStart:
        invitationRequest?.proposalDateTimesToBeHeldStart,
      proposalDateTimesToBeHeldEnd:
        invitationRequest?.proposalDateTimesToBeHeldEnd,
    };
    parsedResult.push(updatedInvitationItem);
  }
  return parsedResult;
};

export const parseMyInvitationsDataNew = (result, currentUserId) => {
  const parsedResult = [];
  for (const invitationRequest of result) {
    const { participants, remainingCounter } = getAllParticipantsInEvent(
      invitationRequest.invitees || [],
      {
        id: invitationRequest.hostPersonID,
        firstName: invitationRequest.hostFirstName,
        lastName: invitationRequest.hostLastName,
      },
      currentUserId,
    );
    const updatedInvitationItem = {
      isHost: currentUserId === invitationRequest.hostPersonID,
      meetingProposalID: extractAndParseValue(invitationRequest, "id", ""),
      mepaId: invitationRequest?.mepaid,
      host: extractAndParseValue(invitationRequest, "hostTitle", ""),
      hostPersonID: extractAndParseValue(invitationRequest, "hostPersonID", ""),
      // topic: extractAndParseValue(invitationRequest, "proposalTopic", ""),
      topic: extractAndParseValue(invitationRequest, "topic", "", sliceOutText),
      agendaFull: extractAndParseValue(invitationRequest, "topic", ""),
      canExapandAgendaText: extractAndParseValue(
        invitationRequest,
        "agenda",
        false,
        isAgendaTextOverflows,
      ),
      meetingWindow: getReadableDateRange(
        invitationRequest.dateTimesToBeHeldStart,
        invitationRequest.dateTimesToBeHeldEnd,
      ),
      start: extractAndParseValue(
        invitationRequest,
        "dateTimesToBeHeldStart",
        "",
      ),
      end: extractAndParseValue(invitationRequest, "dateTimesToBeHeldEnd", ""),
      minLength: invitationRequest.minLength,
      eventDuration: extractAndParseValue(
        invitationRequest,
        "minLength",
        "",
        convertTimeToHours,
      ),
      meetingDuration: extractAndParseValue(
        invitationRequest,
        "minLength",
        "",
        convertDurationToHumanReadable,
      ),
      response: extractAndParseValue(invitationRequest, "response", null),
      nonAdjustable: invitationRequest.nonAdjustable,
      platformId: extractAndParseValue(invitationRequest, "platformId", ""),
      platformTitle: extractAndParseValue(
        invitationRequest,
        "platformTitle",
        "",
        parseMeetingPlatformTitle,
      ),
      invitees: extractAndParseValue(invitationRequest, "invitees", []),
      participants,
      remainingCounter,
      invitationResponse: invitationRequest?.invitationResponse,
      mepA_FixTime: invitationRequest?.mepA_FixTime,
      lastScheduleTime: extractAndParseValue(
        invitationRequest,
        "lastScheduleTime",
        null,
      ),
      mepA_ShouldBeManuallyFixedBefore:
        invitationRequest?.mepA_ShouldBeManuallyFixedBefore,
      mepA_FixedProposalId: invitationRequest?.mepA_FixedProposalId,
      mepA_ScheduledProposalsCount:
        invitationRequest?.mepA_ScheduledProposalsCount,
      mepA_TimeSlotsFinalizationTime:
        invitationRequest?.mepA_TimeSlotsFinalizationTime,
    };
    parsedResult.push(updatedInvitationItem);
  }
  return parsedResult;
};

export const getUserIds = (usersList) => {
  return usersList.map((user) => user.id);
};

export const parseNotificationsData = (result, userTimezone) => {
  const parsedNotifications = [];
  const newlyDeliveredNotifsId = [];

  for (const item of result) {
    const { id, body, sendTime, receiveTime, viewTime } = item;
    parsedNotifications.push({
      id,
      body,
      dateTimeDisplay: parseAndFormatProvidedTime(
        "DD MMM, YYYY HH:mm",
        userTimezone,
      )(sendTime),
      isUnread: !viewTime,
    });

    if (!receiveTime && !viewTime) {
      newlyDeliveredNotifsId.push(id);
    }
  }

  return [parsedNotifications, newlyDeliveredNotifsId];
};
