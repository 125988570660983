import * as Yup from "yup";
import dayjs from "dayjs";
import {
  convertNumberToString,
  extractAndParseValue,
  getUserIds,
} from "./dataParser";
import { convertHoursToMinutes, getCurrentDateTime } from "./dateTime";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../store/user";

export const serverConfigFormSchema = Yup.object().shape({
  url: Yup.string()
    .url("Please provide a valid url.")
    .required("This field is mandatory."),
});

export const loginFormSchema = Yup.object().shape({
  username: Yup.string().min(1).max(50).required("This field is mandatory."),
  password: Yup.string().min(1).max(50).required("This field is mandatory. "),
});

export const passwordRecoverFormSchema = Yup.object().shape({
  username: Yup.string()
    .email("Please provide a valid email.")
    .required("This field is mandatory."),
});

export const getInitialAbsenceFromValues = (absence) => {
  const currentDy = dayjs().format("YYYY-MM-DD");
  return {
    id: extractAndParseValue(absence, "id", ""),
    fromDate: extractAndParseValue(absence, "fromDate", currentDy),
    toDate: extractAndParseValue(absence, "toDate", currentDy),
    fromTime: extractAndParseValue(absence, "fromTime", ""),
    toTime: extractAndParseValue(absence, "toTime", ""),
    motivation: extractAndParseValue(absence, "motivation", ""),
    isAllDay: extractAndParseValue(absence, "isAllDay", false),
  };
};

export const absenceFormSchema = Yup.object().shape({
  fromDate: Yup.string().required("This field is mandatory."),
  toDate: Yup.string().required("This field is mandatory."),
  isAllDay: Yup.boolean(),
  motivation: Yup.string().required("This field is mandatory."),
  fromTime: Yup.string().when(["isAllDay"], {
    is: (isAllDay) => !isAllDay,
    then: () => Yup.string().required("This field is mandatory."),
  }),
  toTime: Yup.string().when(["isAllDay", "fromTime"], {
    is: (isAllDay) => !isAllDay,
    then: () =>
      Yup.string()
        .required("This field is mandatory.")
        .notOneOf(
          [Yup.ref("fromTime")],
          "End time must be different from start time"
        ),
  }),
});

export const meetingProposalFormSchema = Yup.object().shape({
  topic: Yup.string().required("This field is mandatory."),
  agenda: Yup.string(),
  minLength: Yup.number()
    .min(15, "Meeting length should be at least 15 minutes.")
    .required("This field is mandatory."),
  platformId: Yup.string().required("This field is mandatory."),
  dateTimesToBeHeldStart: Yup.string().required("This field is mandatory."),
  dateTimesToBeHeldEnd: Yup.string().required("This field is mandatory."),
  nonAdjustable: Yup.boolean(),
  invitees: Yup.array()
    .of(Yup.string())
    .min(1, "At least one invitee is required"),
});
export const mepaMeetingProposalFormSchema = Yup.object().shape({
  topic: Yup.string().required("This field is mandatory."),
  agenda: Yup.string(),
  minLength: Yup.number()
    .min(15, "Meeting length should be at least 15 minutes.")
    .required("This field is mandatory."),
  platformId: Yup.string().required("This field is mandatory."),
  dateTimesToBeHeldStart: Yup.string().required("This field is mandatory."),
  dateTimesToBeHeldEnd: Yup.string().required("This field is mandatory."),
  // nonAdjustable: Yup.boolean(),
  invitees: Yup.array()
    .of(Yup.string())
    .min(0, "At least one internal invitee is required"),
  externalInvitees: Yup.array().min(
    1,
    "At least one external invitee is required"
  ),
});

export const getInitialMeetingRequestData = (data) => {
  return {
    id: extractAndParseValue(data, "entityID", undefined),
    topic: extractAndParseValue(data, "topic", ""),
    agenda: extractAndParseValue(data, "agenda", ""),
    platformId: extractAndParseValue(
      data,
      "platformId",
      "",
      convertNumberToString
    ),
    dateTimesToBeHeldStart: new Date(
      Date.now()
    ).toISOString(),
    dateTimesToBeHeldEnd: new Date(
      Date.now() + 10 * 24 * 60 * 60 * 1000
    ).toISOString(),
    nonAdjustable: extractAndParseValue(data, "nonAdjustable", false),
    minLength: extractAndParseValue(
      data,
      "eventDuration",
      "30",
      convertHoursToMinutes
    ),
    invitees: extractAndParseValue(data, "invitees", [], getUserIds),
    // externalInvitees: extractAndParseValue(data, "externalInvitees", []),
    creationTime: extractAndParseValue(data, "creationTime", ""),
    hostPersonID: extractAndParseValue(data, "hostPersonID", ""),
    isProposal: extractAndParseValue(data, "isProposal", true),
    // lastModifiedBy: extractAndParseValue(data, 'hostPersonID', ''),
    // createdBy: extractAndParseValue(data, 'hostPersonID', ''),
  };
};
export const GetMepaInitialMeetingRequestData = (data) => {
  const currentUser = useSelector(selectLoggedInUser);
  return {
    id: extractAndParseValue(data, "entityID", undefined),
    topic: extractAndParseValue(data, "topic", ""),
    agenda: extractAndParseValue(data, "agenda", ""),
    // platformId: extractAndParseValue(
    //   data,
    //   "platformId",
    //   "",
    //   convertNumberToString
    // ),
    dateTimesToBeHeldStart: extractAndParseValue(
      data,
      "start",
      getCurrentDateTime("YYYY-MM-DD")
    ),
    dateTimesToBeHeldEnd: extractAndParseValue(
      data,
      "end",
      getCurrentDateTime("YYYY-MM-DD")
    ),
    // nonAdjustable: extractAndParseValue(data, "nonAdjustable", false),
    minLength: extractAndParseValue(
      data,
      "eventDuration",
      "",
      convertHoursToMinutes
    ),
    invitees: extractAndParseValue(data, "invitees", [], getUserIds),
    // externalInvitees: extractAndParseValue(
    //   data,
    //   "externalInvitees",
    //   [],
    //   getUserIds
    // ),
    creationTime: extractAndParseValue(data, "creationTime", ""),
    hostPersonID: extractAndParseValue(data, "hostPersonID", ""),
    hostPersonFirstName: extractAndParseValue(currentUser, "userFirstName", ""),
    hostPersonLastName: extractAndParseValue(currentUser, "userLastName", ""),
    hostPersonUserName: extractAndParseValue(currentUser, "userName", ""),
    isProposal: extractAndParseValue(data, "isProposal", true),
    // lastModifiedBy: extractAndParseValue(data, 'hostPersonID', ''),
    // createdBy: extractAndParseValue(data, 'hostPersonID', ''),
  };
};
